<!--系统设置-监测点参数配置-->
<template>
  <section>
    <!-- 工具条 -->
    <el-col :span="24" class="toolbar" style="padding-bottom: 0px">
      <el-form :inline="true" :model="filter" size="mini">
        <el-form-item>
          <el-input
            v-model="filter.val"
            placeholder="关键字搜索"
            @keyup.native.enter="getData"
          >
            <el-select
              v-model="filter.field"
              slot="prepend"
              placeholder="请选择"
            >
              <el-option label="监测点" value="Name"></el-option>
              <el-option label="地址" value="Addr"></el-option>
              <el-option label="设备编号" value="MnLast"></el-option>
            </el-select>
            <el-button
              slot="append"
              icon="el-icon-refresh"
              @click="handleRefresh"
            ></el-button>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-cascader
            placeholder="所属单位"
            @change="getData"
            v-model="filter.owner"
            :options="customerTree"
            :props="customerProps"
            clearable
            filterable
          />
        </el-form-item>
        <el-form-item>
          <el-cascader
            placeholder="区域"
            @change="getData"
            v-model="filter.area"
            :options="areaTree"
            :props="selAreaProps"
            clearable
            filterable
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getData">查询</el-button>
        </el-form-item>
      </el-form>
    </el-col>
    <!-- 列表 -->
    <el-col :span="24">
      <el-table
        :data="datalist.content"
        size="mini"
        border
        highlight-current-row
        v-loading="loading"
        @selection-change="selsChange"
        :max-height="clientHeight"
        style="width: 100%"
      >
        <el-table-column type="selection"/>
        <el-table-column type="index" label="#" align="center" width="55"/>
        <el-table-column
          prop="Name"
          label="监测点"
          show-overflow-tooltip
          width="180"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="MnLast"
          label="设备编号"
          show-overflow-tooltip
          width="160"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="Owner"
          :formatter="getPname"
          width="200"
          label="所属单位"
          show-overflow-tooltip
          header-align="center"
        />
        <!--  -->
        <el-table-column
          prop="Addr"
          label="地址"
          show-overflow-tooltip
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="AreaId"
          :formatter="getArea"
          show-overflow-tooltip
          width="120"
          label="所属地区"
          align="center"
          header-align="center"
        />
      </el-table>
    </el-col>

    <!-- 工具条 -->
    <el-col :span="24" class="toolbar">
      <el-button
        type="primary"
        size="mini"
        @click="bulkSetting"
        :disabled="sels.length === 0"
      >批量设置
      </el-button
      >
      <el-pagination
        small
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="filter.page"
        :page-sizes="filter.pageSizes"
        layout="total, sizes, prev, pager, next, jumper"
        :total="datalist.total"
        style="display: inline-block; margin-left: 15px"
      >
      </el-pagination>
    </el-col>
    <el-dialog title="" :visible.sync="settingVisible" width="80%">
      <el-form
        :model="formData"
        label-width="130px"
        :rules="formRule"
        ref="form"
        size="mini"
      >

        <el-row class="form-part">
          <el-form-item label="监测参数设置"></el-form-item>
          <el-col :span="10">
            <el-form-item prop="EmissionsSill" label="油烟阈值">
              <el-input-number v-model.trim="formData.emissions_sill"/>
            </el-form-item>
            <el-form-item prop="GranuleSill" label="颗粒物阈值">
              <el-input-number v-model.trim="formData.granule_sill"/>
            </el-form-item>
            <el-form-item prop="HydrocarbonSill" label="非甲烷总烃阈值">
              <el-input-number v-model.trim="formData.hydrocarbon_sill"/>
            </el-form-item>
            <el-form-item prop="MaintainerId" label="折算浓度计算方式">
              <el-select
                clearable
                placeholder="请选择"
                v-model.trim="formData.surpass_calc_method"
              >
                <el-option label="抽样计算" :value="1"/>
                <el-option label="滑动计算" :value="2"/>
              </el-select>
            </el-form-item>
            <el-form-item prop="LinkRatioSill" label="参数超标判断">
              <el-checkbox v-model="checkedEmission">油烟浓度</el-checkbox>
              <el-checkbox v-model="checkedGranule">颗粒物浓度</el-checkbox>
              <el-checkbox v-model="checkedHydrocarbon">非甲烷总烃浓度</el-checkbox>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item prop="OfflineJudge" label="异常判定">
              <el-input-number v-model.trim="formData.offline_judge"/>
            </el-form-item>
            <el-form-item prop="Samplings" label="抽样次数">
              <el-input-number v-model.trim="formData.samplings"/>
            </el-form-item>
            <el-form-item prop="LinkStatus" label="是否联动">
              <el-radio v-model.trim="formData.link_status" :label="1">是</el-radio>
              <el-radio v-model.trim="formData.link_status" :label="0">否</el-radio>
            </el-form-item>
            <el-form-item prop="LinkRatioSill" label="联动比阈值">
              <el-input-number v-model.trim="formData.link_ratio_sill"/>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item>
              <el-button type="primary" @click="handleSubmit">提交</el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="form-part" style="flex-wrap: wrap;flex-direction: column">
          <el-form-item label="油烟码判断规则">
            <template>
              <p>时间段：最近X天</p>
              <p>
                Ａ、绿码：最近X天内，该店家超标天数小等于Value1（默认3）次的，且净化器不正常使用天数小等于Value2（默认3）天的，且异常离线天数小等于Value3（默认3），且
                超标天数 + 净化器不正常使用天数 + 离线天数 + X天内待处理天数小等于value4（默认3）。
              </p>
             
            </template>
          </el-form-item>
          <el-row>
            <el-col :span="4">
              <el-form-item prop="HealthCodeX" label="X">
                <el-input-number class="number_input" v-model.trim="formData.health_code_x"/>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item prop="HealthCodeValue1" label="Value1">
                <el-input-number class="number_input" v-model.trim="formData.health_code_value1"/>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item prop="HealthCodeValue2" label="Value2">
                <el-input-number class="number_input" v-model.trim="formData.health_code_value2"/>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item prop="HealthCodeValue3" label="Value3">
                <el-input-number class="number_input" v-model.trim="formData.health_code_value3"/>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item prop="HealthCodeValue4" label="Value4">
                <el-input-number class="number_input" v-model.trim="formData.health_code_value4"/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="5">
              <div>健康码判断参数选择</div>
            </el-col>
            <el-col :span="5">
              <el-checkbox v-model="checkedExceed">超标</el-checkbox>
            </el-col>
            <el-col :span="5">
              <el-checkbox v-model="checkedAbnormal">不正常使用净化器</el-checkbox>
            </el-col>
            <el-col :span="5">
              <el-checkbox v-model="checkedStore">异常离线</el-checkbox>
            </el-col>
            <el-col :span="4">
              <el-button type="primary" @click="handleSubmit">提交</el-button>
            </el-col>
          </el-row>
        </div>

        <div class="form-part" style="flex-wrap: wrap;flex-direction: column">
          <el-form-item label="异常数据判定规则">
            <el-col :span="24">
              <p>
                【1】、0~24点间油烟浓度（颗粒物、非甲烷总烃）三个值中的一项值一直小于Value7(默认0.1）mg/m³的，视为数据异常偏小；
              </p>
              <p>
                【2】、0~24点间油烟浓度（颗粒物、非甲烷总烃）三个值中的一项值一直大于Value8(默认10）mg/m³的，视为数据异常偏大；
              </p>
              <p>
                【3】、0~24点间油烟浓度（颗粒物、非甲烷总烃）三个值中的一项值一直非Value9(默认0）mg/m³的，视为数据漂移异常；
              </p>
              <p>
                【4】、0~24点间上线时间大等于1分钟且小于Value10（默认60）分钟的，视为上线时间过短异常；
              </p>
            </el-col>
          </el-form-item>
          <el-row>

          </el-row>
          <el-row>
            <el-col :span="6">
              <el-form-item prop="AbnormalValue7" label="Value7">
                <el-input-number v-model.trim="formData.abnormal_value7"/>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item prop="AbnormalValue8" label="Value8">
                <el-input-number v-model.trim="formData.abnormal_value8"/>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item prop="AbnormalValue9" label="Value9">
                <el-input-number v-model.trim="formData.abnormal_value9"/>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item prop="AbnormalValue10" label="Value10">
                <el-input-number v-model.trim="formData.abnormal_value10"/>
              </el-form-item>
            </el-col>
          </el-row>
        </div>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" size="mini" @click.native="handleSubmit"
        >确认
        </el-button
        >
        <el-button size="mini" @click.native="settingVisible = false"
        >取消
        </el-button
        >
      </div>
    </el-dialog>
  </section>
</template>

<script>
import {mapState} from 'vuex'
// import { dateFormater } from "@/util/index";
import {
  getSinglePname,
  getPname,
  ifNull,
  loadData,
  getUserInfo,
  join, ownerJoin
} from '@/util/index'

export default {
  name: 'MonitoringParameters',
  data() {
    return {
      loading: false,
      datalist: {},
      userInfo: {},
      org: null,
      sels: [], // 列表选中列
      settingVisible: false,
      formData: {
        health_code_value1: 0,
        health_code_value2: 0,
        health_code_value3: 0,
        health_code_value4: 0,
        health_code_value11: 0,
        abnormal_value7: 0,
        abnormal_value8: 0,
        abnormal_value9: 0,
        abnormal_value10: 0,
        health_code_x: 0,
        samplings: 0,
        surpass_calc_method: 0,
        offline_judge: 0,
        link_status: 0,
        emissions_sill: 0,
        granule_sill: 0,
        hydrocarbon_sill: 0,
        link_ratio_sill: 0,
      },
      maintenanceList: [], // 维保人员列表
      filter: {
        val: null,
        owner: null,
        area: null,
        field: 'Name',
        page: 1,
        size: 20,
        pageSizes: [20, 50, 100]
      },
      formRule: {},
      checkedExceed: false,
      checkedAbnormal: false,
      checkedStore: false,
      checkedEmission: false,
      checkedGranule: false,
      checkedHydrocarbon: false,
    }
  },
  computed: {
    ...mapState(['areaTree', 'clientHeight', 'locale', 'customerTree']),
    ...mapState({
      props: (state) => Object.assign({}, state.props, {label: 'Org'}),
      customerProps: (state) =>
        Object.assign({}, state.props, {label: 'Org', checkStrictly: true}),
      selAreaProps: (state) =>
        Object.assign({}, state.props, {checkStrictly: true}),
      areaProps: (state) => Object.assign({}, state.props)
    })
  },
  mounted() {
    try {
      const user = getUserInfo()
      this.userInfo = user.Info
    } catch (e) {
      this.$message.error(e)
    }
    this.getData()
  },
  methods: {
    getPname(r, c) {
      return getPname(this.customerTree, r[c.property], 'Org').join('/')
    },
    getArea(r, c) {
      return getSinglePname(this.areaTree, r[c.property])
    },
    getData() {
      this.loading = true
      let param = {
        StartAt: (this.filter.page - 1) * this.filter.size,
        Size: this.filter.size,
        Param: {}
      }
      // 所属单位搜索
      if (this.filter.owner && this.filter.owner.length > 0) {
        const owner = `${ownerJoin(this.filter.owner, this.userInfo)}`
        param.Param['Owner'] = owner
      }
      //关键字搜索
      if (this.filter.field && this.filter.val) {
        param.Param[this.filter.field] = this.filter.val
      }
      //区域信息搜索
      if (this.filter.area && this.filter.area.length !== 0) {
        param.Param['Area'] = join(this.filter.area)
      }
      this.$post("admin/listLocale", param).then(res => {
        this.datalist.content = res.content
        this.datalist.total = res.total
        this.itemKey = Math.random()
        this.loading = false
      })
    },
    selsChange(sels) {
      this.sels = sels.map((v) => {
        return v.Id
      })
    },
    bulkSetting() {
      let item = this.datalist.content.filter(item => item.Id === this.sels[0])[0]
      this.formData.health_code_value1 = item.HealthCodeValue1
      this.formData.health_code_value2 = item.HealthCodeValue2
      this.formData.health_code_value3 = item.HealthCodeValue3
      this.formData.health_code_value4 = item.HealthCodeValue4
      this.formData.health_code_value11 = item.HealthCodeValue11
      this.formData.health_code_value12 = item.HealthCodeValue12
      this.formData.abnormal_value7 = item.AbnormalValue7
      this.formData.abnormal_value8 = item.AbnormalValue8
      this.formData.abnormal_value9 = item.AbnormalValue9
      this.formData.abnormal_value10 = item.AbnormalValue10
      this.formData.health_code_x = item.HealthCodeX
      this.formData.samplings = item.Samplings
      this.formData.surpass_calc_method = item.SurpassCalcMethod
      this.formData.offline_judge = item.OfflineJudge
      this.formData.link_status = item.LinkStatus
      this.formData.emissions_sill = item.EmissionsSill
      this.formData.granule_sill = item.GranuleSill
      this.formData.hydrocarbon_sill = item.HydrocarbonSill
      this.formData.link_ratio_sill = item.LinkRatioSill
      this.checkedExceed = false
      this.checkedAbnormal = false
      this.checkedStore = false
      this.checkedEmission = false
      this.checkedGranule = false
      this.checkedHydrocarbon = false

      if ([1, 3, 5, 7].indexOf(item.HealthCodeValue11) > -1) {
        this.checkedExceed = true
      }
      if ([2, 3, 6, 7].indexOf(item.HealthCodeValue11) > -1) {
        this.checkedAbnormal = true
      }
      if ([4, 5, 6, 7].indexOf(item.HealthCodeValue11) > -1) {
        this.checkedStore = true
      }
      if ([1, 3, 5, 7].indexOf(item.HealthCodeValue12) > -1) {
        this.checkedEmission = true
      }
      if ([2, 3, 6, 7].indexOf(item.HealthCodeValue12) > -1) {
        this.checkedGranule = true
      }
      if ([4, 5, 6, 7].indexOf(item.HealthCodeValue12) > -1) {
        this.checkedHydrocarbon = true
      }
      console.log(this.checkedExceed,this.checkedAbnormal,this.checkedStore)
      this.settingVisible = true
    },
    handleSubmit() {
      this.$confirm('确认提交吗？', '提示', {})
        .then(() => {
          const para = Object.assign({}, this.formData)
          para.id = this.sels.join(',')
          let value = 0
          if (this.checkedExceed) value += 1
          if (this.checkedAbnormal) value += 2
          if (this.checkedStore) value += 4
          para.health_code_value11 = value

          let alarmValue = 0
          if (this.checkedEmission) alarmValue += 1
          if (this.checkedGranule) alarmValue += 2
          if (this.checkedHydrocarbon) alarmValue += 4
          para.health_code_value12 = alarmValue


          let param = new URLSearchParams()
          Object.entries(para).forEach(([key, val]) => {
            param.append(key, val)
          })
          this.$post('admin/locale/value/edit', param)
            .then(() => {
              this.$message({
                type: "success",
                message: "修改成功"
              })
              this.getData()
              this.settingVisible = false
            })
        })
    },
    handleRefresh() {
      this.filter.page = 1
      this.filter.val = null
      this.filter.owner = null
      this.filter.area = null
      loadData(
        this,
        (res) => {
          this.$store.dispatch('setLocale', res).then(() => {
            this.getData()
          })
        },
        3
      )
    },
    // 改变页容量
    handleSizeChange(size) {
      this.filter.size = size
      this.getData()
    },
    // 翻页
    handleCurrentChange(page) {
      this.filter.page = page
      this.getData()
    },

  }
}
</script>

<style lang="scss" scoped>
.el-select--mini ::v-deep .el-input--suffix {
  width: 120px;
}

.number_input {
  width: 120px !important;
}

.form-part {
  display: flex;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
  border-width: 1px;
  border-style: solid;
  border-radius: 10px;
  border-color: #eeeeee;
}
</style>
